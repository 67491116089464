// import $ from 'jquery';

// const ready = function() {
//   $(window).scroll(function() {
//     if ($(this).scrollTop() > 600) {
//       $('.js-to-top-btn').fadeIn(200);
//     } else {
//       $('.js-to-top-btn').fadeOut(200);
//     }
//   });
// };

// $(document).ready(ready);
