import $ from 'jquery';

var ready = function() {
  resizeDoings();
  $(window).load(resizeDoings);
  $(window).resize(resizeDoings);
  $(window).scroll(scrollDoings);

  $('.js-single-scroll-link').click(function(event) {
    event.preventDefault();

    var linkOffset = 0;

    if ($($.attr(this, 'href')).data('scroll-link-offset')) {
      linkOffset = $($.attr(this, 'href')).data('scroll-link-offset');
    }

    $('html, body').animate(
      {
        scrollTop: $($.attr(this, 'href')).offset().top + linkOffset,
      },
      300
    );
  });

  $('.sidebar-item').click(function(event) {
    event.preventDefault();

    var linkOffset = 0;

    if ($($.attr(this, 'href')).data('scroll-link-offset')) {
      linkOffset = $($.attr(this, 'href')).data('scroll-link-offset');
    }

    $('html, body').animate(
      {
        scrollTop: $($.attr(this, 'href')).offset().top + linkOffset,
      },
      300
    );

    // $('.contents-section').removeClass('active');
    // $(this).addClass('active');

    activeSection = $(this).index();
  });
};
