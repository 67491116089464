import '../styles/main.scss';
import './nav-mobile';
import './ostrovskiy-popups';
import './sholokhov-popups';
import './phsw-init';
import './smooth-scroll';
import './about';
import './sidebar';
import './background-parallax';
import './awakeness';
import './sidepage';
import './to-top';

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import { registerPlayer } from './plyr';
